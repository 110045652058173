import Vue from 'vue'
import Component from 'vue-class-component'
import Utils from "@/modules/shared/utils/utils";
import {Prop, Watch} from "vue-property-decorator";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import {RangeValue} from "@/components/inputs/SygniRangeInput.vue";
import { TableData } from '@/modules/genprox/modules/profile/store/types';
import { EllipsisHorizontalIcon, MagnyfyingGlassIcon } from '@/assets/icons/heroicons/heroicons';
import moment from 'moment';
import _ from 'lodash';

@Component
export class FilterMixin extends Vue {
    @Prop({ default: null }) tableData: TableData<any>;

    MagnyfyingGlassIcon: any = MagnyfyingGlassIcon;
    EllipsisHorizontalIcon = EllipsisHorizontalIcon;

    itemsUrl: string = '';
    isLoading: boolean = false;
    enableFilterCounting: boolean = true;

    filters: Record<string, Filter> = {};
    orFilters: Record<string, Filter> = {};

    filtersToggle: boolean = false;

    itemsCount: number = 0;
    protected getItemsTimeout: any;
    filterQuery: string = '';
    orFilterQuery: string = '';
    searchQuery: string = null;

    handleSearch(value: any, orFilters: string[]) {
        if (orFilters?.length) {
            orFilters?.forEach((filterName: string) => {
                this.$set(this.orFilters[filterName], 'value', value || null)
            })
        }

        this.applyFilters()
    }

    applyFilters() {
        if(this.isLoading) return;
        this.$emit('filtersChange', `${this.getFilterQuery()}${this.getOrFilterQuery()}`);
    }

    clearAll() {
        this.searchQuery = null
        for (const key in this.orFilters) {
            if(Array.isArray(this.orFilters[key].value)){
                this.orFilters[key].value = [];
            } else if(this.orFilters[key].value?.to !== undefined ) {
                this.orFilters[key].value.to = null;
                this.orFilters[key].value.from = null;
            } else {
                this.orFilters[key].value = null;
            }
        }
        for (const key in this.filters) {
            if(Array.isArray(this.filters[key].value)){
                this.filters[key].value = [];
            } else if(this.filters[key].value?.to !== undefined ) {
                this.filters[key].value.to = null;
                this.filters[key].value.from = null;
            } else {
                this.filters[key].value = null;
            }
        }
        this.applyFilters();
    }

    countItems() {
        this.isLoading = true;
        this.filterQuery = this.getFilterQuery();
        this.orFilterQuery = this.getOrFilterQuery();
        clearTimeout(this.getItemsTimeout);
        this.getItemsTimeout = setTimeout(() => {
            this.getItemsCount().then(() => {
                this.isLoading = false;
            });
        }, 800);
    }

    @Watch('filters', {deep: true}) async onFiltersValueChange() {
        this.countItems()
    }
    
    @Watch('itemsUrl') async onItemsUrlChange() {
        this.countItems()
    }

    async loadDataFromQueryParams() {
        // this.tableData.isLoading = true
        const query = this.$route?.query

        try {
            await new Promise((resolve) => {
                if (this.$route?.fullPath?.includes('?filters') || this.$route?.fullPath?.includes('&filters')) {
                    const filters = this.parseUrlFilters(this.$route?.query)

                    Object.keys(filters)?.forEach((filterName: string) => {
                        const filterData = this.filters[filterName]

                        if (Object.keys(filterData || {})?.length > 0) {
                            if (filterData?.type === 'string' && !_.isEqual(filterData.getQueryValue, FilterFunctions.array)) {
                                filterData.value = filters[filterName][0]?.value
                                filterData.operator = filters[filterName][0]?.operator
                            } else if (filterData?.type === 'date') {
                                const filterFrom = filters[filterName][0]?.operator === 'gte' || filters[filterName][1]?.operator === 'gte'
                                const filterTo = filters[filterName][0]?.operator === 'lte' || filters[filterName][1]?.operator === 'lte'

                                if (filterFrom) {
                                    let from = filters[filterName][0]?.operator === 'gte' ? filters[filterName][0]?.value : filters[filterName][1]?.value
                                    if (from) {
                                        from = moment(from, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD')
                                        filterData.value.from = from
                                    }
                                }

                                if (filterTo) {
                                    let to = filters[filterName][0]?.operator === 'lte' ? filters[filterName][0]?.value : filters[filterName][1]?.value
                                    if (to) {
                                        to = moment(to, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD')
                                        filterData.value.to = to
                                    }
                                }
                            } else if (filterData?.type === 'number') {
                                const filterFrom = filters[filterName][0]?.operator === 'gte' || filters[filterName][1]?.operator === 'gte'
                                const filterTo = filters[filterName][0]?.operator === 'lte' || filters[filterName][1]?.operator === 'lte'

                                if (filterFrom) {
                                    let from = filters[filterName][0]?.operator === 'gte' ? filters[filterName][0]?.value : filters[filterName][1]?.value
                                    if (from) {
                                        from = Number(from)
                                        filterData.value.from = from
                                    }
                                }

                                if (filterTo) {
                                    let to = filters[filterName][0]?.operator === 'lte' ? filters[filterName][0]?.value : filters[filterName][1]?.value
                                    if (to) {
                                        to = Number(to)
                                        filterData.value.to = to
                                    }
                                }
                            } else if (_.isEqual(filterData.getQueryValue, FilterFunctions.array)) {
                                filterData.value = filters[filterName][0]?.value?.split(',')
                                filterData.operator = filters[filterName][0]?.operator
                            }

                            // const filterObject = _.cloneDeep(filterData)
                            // console.log(filterObject)
                                

                            // this.filtersModal.filters[i] = {
                            //   key: filterName,
                            //   ...filterObject
                            // }
                        }
                    })

                    // this.tableData.tableQuery.filters = _.cloneDeep(this.filtersModal?.filters)
                    
                    const filterQueryParams = Object.keys(this.$route?.query)?.filter((key: string) => key?.includes('filters'))
                    const filterQuery = filterQueryParams?.map((key: string) => {
                        return `&${key}=${this.$route?.query[key]}`
                    })?.join('')

                    this.$store.commit('insights/setInsightsTableFiltersQuery', filterQuery)
                    // this.addQueryParam('filters', filterQuery)
                } else {
                    // this.filtersModal.filters = [_.cloneDeep(this.emptyFilterObject)]
                    // this.tableData.tableQuery.filters = []
                    // this.addQueryParam('filters', null)
                }

                resolve(true)
            })
        // eslint-disable-next-line no-empty
        } catch (e: any) {}
    
        this.$nextTick(async () => {
            await new Promise((resolve) => {
                // const numberOfPages = Math.ceil(this.tableData?.totalCount / this.tableData?.perPage)
                // console.log('number of pages', numberOfPages)

                if (query?.perPage) {
                    // console.log('per page', query?.perPage)
                    // this.setTablePerPage(Number(query?.perPage))
                    // this.addQueryParam('perPage', Number(query?.perPage))
                } else {
                    // console.log('per page', null)
                    // this.setTablePerPage(null)
                    // this.addQueryParam('perPage', null)
                }

                resolve(true)
            })

            if (query?.page) {
                this.$nextTick(() => {
                    this.$store.commit('insights/setInsightTableOffsetQuery', this.tableData?.perPage * (Number(query?.page) - 1))
                })
            }
            // this.setTablePage(Number(query?.page) || 1)
            // this.addQueryParam('page', Number(query?.page) || 1)

            if (query['sort[]'] !== undefined) {
                // this.setTableSorting(query['sort[]'])
                // this.addQueryParam('sorting', {
                //   name: query['sort[]']?.replaceAll('-', ''),
                //   order: query['sort[]']?.includes('-') ? '-' : ''
                // })
            } else {
                // this.setTableSorting('')
            }
        })
    }

    // @Watch('tableData.perPage') onPerPageChange() {
    //   console.log('change per page', this.tableData?.perPage)
    // }

    @Watch('tableData.query', { deep: true }) onQueryChange() {
        if (this.tableData) {
            this.$router.push(`/insights${this.buildTableQueryParamsString()}`)
        }
    }

    @Watch('$route.fullPath') onRoutePathChange(newValue: string, oldValue: string) {
        if (this.tableData) {
            const routeNewPath = newValue?.split('?')[0] || ''
            const routeOldPath = oldValue?.split('?')[0] || ''
        
            if (routeNewPath == routeOldPath) {
                this.loadDataFromQueryParams()
                this.$emit('getItems')
            }
        }
    }

    buildTableQueryParamsString() {
        const currentPage = Math.floor(this.tableData?.query?.offset / this.tableData?.perPage) + 1
        let queryString = ''

        if (this.tableData?.query?.filters) {
            let filtersQuery = this.tableData?.query?.filters

            if (!queryString) {
                if (filtersQuery[0] == '&') {
                filtersQuery = filtersQuery.substring(1)
                }

                queryString += `?${filtersQuery}`
            } else {
                queryString += filtersQuery
            }
        }

        if (currentPage) {
            queryString += queryString ? `&page=${currentPage}` : `?page=${currentPage}`
        }

        if (this.tableData?.query?.sorting && this.tableData?.query?.sorting?.name) {
            if (queryString) {
                queryString += `&sort[]=${this.tableData?.query?.sorting?.order || ''}${this.tableData?.query?.sorting?.name}`
            } else {
                queryString += `?sort[]=${this.tableData?.query?.sorting?.order || ''}${this.tableData?.query?.sorting?.name}`
            }
        }

        return queryString
    }

    parseUrlFilters(query: any) {
        const filterValues = Object.keys(query)?.filter((el: any) => el?.includes('filters['))
        const filters: any = {}

        filterValues?.forEach((filterKey: string) => {
            const filterValue: any = /(filters)\[(.*?)\]\[(\d)]\[value]/g.exec(filterKey)
            const filterOperator: any = /(filters)\[(.*?)\]\[(\d)]\[operator]/g.exec(filterKey)

            if (filterValue && filterValue[0] && filterValue[2]) {
                if (filters[filterValue[2]] === undefined) {
                    filters[filterValue[2]] = []
                    filters[filterValue[2]][filterValue[3]] = { value: query[filterKey] }
                } else {
                    if (filters[filterValue[2]][filterValue[3]] === undefined) {
                        filters[filterValue[2]][filterValue[3]] = { value: query[filterKey] }
                    } else {
                        filters[filterValue[2]][filterValue[3]].value = query[filterKey]
                    }
                }
            }
        
            if (filterOperator && filterOperator[0] && filterOperator[2]) {
                if (filters[filterOperator[2]] === undefined) {
                    filters[filterOperator[2]] = []
                    filters[filterOperator[2]][filterOperator[3]] = { operator: query[filterKey] }
                } else {
                    if (filters[filterOperator[2]][filterOperator[3]] === undefined) {
                        filters[filterOperator[2]][filterOperator[3]] = { operator: query[filterKey] }
                    } else {
                        filters[filterOperator[2]][filterOperator[3]].operator = query[filterKey]
                    }
                }
            }
        })

        return filters
    }

    protected async getItemsCount() {
        const response = await axios.get(`${BACKEND_API_URL}/${this.itemsUrl}?${this.filterQuery}${this.orFilterQuery}&limit=0`,{
            headers: {'x-total-count-only': true}
        });
        this.itemsCount = response.headers['x-total-count'];
    }

    onMounted(){
        this.onFiltersValueChange();
    }

    createCustomQuery(filter: Filter, filterName: string): string{
        return '';
    }

    getFilterQuery(): string {
        this.filterQuery = '';
        for( const key in this.filters) {
            if (this.filters[key]?.value && this.filters[key]?.value !== '') {
                this.filterQuery += this.createSingleFilterQuery(this.filters[key], key);
            }
        }
        return this.filterQuery;
    }

    getOrFilterQuery(): string {
        this.orFilterQuery = '';
        for( const key in this.orFilters) {
            if (this.orFilters[key]?.value && this.orFilters[key]?.value !== '') {
                this.orFilterQuery += this.createSingleOrFilterQuery(this.orFilters[key], key);
            }
        }
        return this.orFilterQuery;
    }

    createSingleFilterQuery(filter: Filter, filterName: string): string {
        let query: string = this.createCustomQuery(filter, filterName);
        if(query !== '') return query;


        if (filter.getQueryValue === FilterFunctions.arrayContain) {
            for (let index = 0; index < filter.value.length; index++) {
                const filterValue = filter?.value[index];
                query += `&filters[${filterName}][${index}][operator]=${filter.operator}`
                query += `&filters[${filterName}][${index}][value]=${filterValue}`
            }
            return query
        }

        let queryValue = filter.getQueryValue(filter.value);
        if(queryValue === '') return '';
        switch(filter.type) {
            case('dateRange'):
                if(filter.value[0] === null || filter.value[1] === null) break;
                queryValue = filter?.getQueryValue(filter.value,0);
                query += `&filters[${filterName}][0][value]=${queryValue}`;
                query += `&filters[${filterName}][0][operator]=gte`;
                queryValue = filter.getQueryValue(filter.value,1);
                query += `&filters[${filterName}][1][value]=${queryValue}`;
                query += `&filters[${filterName}][1][operator]=lte`;
                break;
            case('range'):
                if(filter?.value?.from === null && filter?.value?.to === null) break;
                queryValue = filter.getQueryValue(filter?.value, 0);
                if(queryValue !== null) {
                    query += `&filters[${filterName}][0][value]=${queryValue}`;
                    query += `&filters[${filterName}][0][operator]=gte`;
                }
                queryValue = filter.getQueryValue(filter.value,1);
                if(queryValue !== null) {
                    query += `&filters[${filterName}][1][value]=${queryValue}`;
                    query += `&filters[${filterName}][1][operator]=lte`;
                }
                break;
            case('string'): {
                query += `&filters[${filterName}][0][value]=${queryValue}`;
                query += `&filters[${filterName}][0][operator]=${filter.operator}`;
                break;
            }
        }
        return query;
    }

    createSingleOrFilterQuery(filter: Filter, filterName: string): string {
        let query: string = this.createCustomQuery(filter, filterName);
        if(query !== '') return query;


        if (filter.getQueryValue === FilterFunctions.arrayContain) {
            for (let index = 0; index < filter.value.length; index++) {
                const filterValue = filter?.value[index];
                query += `&or-filters[${filterName}][${index}][operator]=${filter.operator}`
                query += `&or-filters[${filterName}][${index}][value]=${filterValue}`
            }
            return query
        }

        let queryValue = filter.getQueryValue(filter.value);
        if(queryValue === '') return '';
        switch(filter.type) {
            case('dateRange'):
                if(filter.value[0] === null || filter.value[1] === null) break;
                queryValue = filter?.getQueryValue(filter.value,0);
                query += `&or-filters[${filterName}][0][value]=${queryValue}`;
                query += `&or-filters[${filterName}][0][operator]=gte`;
                queryValue = filter.getQueryValue(filter.value,1);
                query += `&or-filters[${filterName}][1][value]=${queryValue}`;
                query += `&or-filters[${filterName}][1][operator]=lte`;
                break;
            case('range'):
                if(filter?.value?.from === null && filter?.value?.to === null) break;
                queryValue = filter.getQueryValue(filter?.value, 0);
                if(queryValue !== null) {
                    query += `&or-filters[${filterName}][0][value]=${queryValue}`;
                    query += `&or-filters[${filterName}][0][operator]=gte`;
                }
                queryValue = filter.getQueryValue(filter.value,1);
                if(queryValue !== null) {
                    query += `&or-filters[${filterName}][1][value]=${queryValue}`;
                    query += `&or-filters[${filterName}][1][operator]=lte`;
                }
                break;
            case('string'): {
                query += `&or-filters[${filterName}][0][value]=${queryValue}`;
                query += `&or-filters[${filterName}][0][operator]=${filter.operator}`;
                break;
            }
        }
        return query;
    }

    addRemoveArrayValue(filterName: string,value: string): void {
        const array: Array<string> = this.filters[filterName]?.value;
        if(array.indexOf(value) === -1) {
            array.push(value);
        } else {
            array.splice(array.indexOf(value), 1);
        }
    }

    getArrayValue(filterName: string,value: string): boolean {
        return this.filters[filterName]?.value?.indexOf(value) !== -1;
    }
}

export const FilterFunctions: any = {
    string: (value: string) => { return encodeURIComponent(value) },
    range: (value: RangeValue, index: number) => { return index === 0 ? value.from : value.to },
    dateRange: (value: Array<any>, index: number) => { if(!value[0]){return ''} return index === 0 ? `${value[0]} 00:00:00` : `${value[1]} 23:59:59`},
    source: (value: any) => { return value.id },
    array: (value: Array<string>) => { return encodeURIComponent(value?.join(',')); },
    arrayContain: (value: Array<string>) => {
        return encodeURIComponent(value?.join(','));
    },
}

export const FilterHelpers: any = {
    parseURLFilters: (url: string) => {
        const queryStart = url.indexOf("?") + 1,
            queryEnd   = url.indexOf("#") + 1 || url.length + 1,
            query = url.slice(queryStart, queryEnd - 1),
            pairs = query.replace(/\+/g, " ").split("&"),
            parms: any = {};

        let i: any;
        let n: any;
        let v: any;
        let nv: any;

        if (query === "") return;

        for (i = 0; i < pairs.length; i++) {
            nv = pairs[i].split("=", 2);
            n = decodeURIComponent(nv[0]);
            v = decodeURIComponent(nv[1]);

            if(n.includes('value')) {
                n = n.replace('filters', '');
                n = n.replace('[value]', '');
                // eslint-disable-next-line no-prototype-builtins
                if (!parms.hasOwnProperty(n)) parms[n] = [];
                parms[n].push(nv.length === 2 ? v : null);
            }

        }

        return parms;
    },
}

export interface Filter{
    value: any,
    operator: FilterOperators,
    type: string,
    getQueryValue?: (value: any, index?: any) => string,
    label?: string,
}


export enum FilterOperators {
    gte = 'gte',
    gt = 'gt',
    eq =  'eq',
    lte =  'lte',
    lt =  'lt',
    in = 'in',
    like =  'like',
    range = 'range',
    contain = 'contain',
    dateRange = 'dateRange',
}
